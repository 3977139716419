import React from "react";
import { GoogleMap, Marker, Circle } from "@react-google-maps/api";
import profileImage from "assets/images/avatar.jpeg";
import { Button } from "@mui/material";
import DirectionsIcon from "@mui/icons-material/Directions";

const MapComponent = ({ latitude, longitude }) => {
  const mapStyles = {
    height: "400px",
    width: "100%",
  };

  const defaultCenter = {
    lat: latitude,
    lng: longitude,
  };

  //   const customMarkerIcon = {
  //     url: "https://picsum.photos/200/300", // Replace this with the URL of your custom image
  //     scaledSize: new window.google.maps.Size(40, 40), // Adjust the size of the image as per your requirement
  //     origin: new window.google.maps.Point(0, 0),
  //     anchor: new window.google.maps.Point(20, 40),
  //   };
  const FindDirectionsButton = ({ destination }) => {
    const mapsUri = `https://www.google.com/maps?q=${encodeURIComponent(
      destination
    )}`;

    const openMapsApp = (e) => {
      e.preventDefault();
      window.open(mapsUri, "_blank");
    };

    return (
      <Button
        startIcon={<DirectionsIcon />}
        variant="outlined"
        onClick={openMapsApp}
        sx={{ mb: "15px" }}
      >
        Direction
      </Button>
    );
  };

  const circleRadius = 200;

  return (
    <>
      <FindDirectionsButton destination="NE Marketplace Dr and 221st Ln NE Redmond WA 98053" />
      <GoogleMap mapContainerStyle={mapStyles} zoom={13} center={defaultCenter}>
        {/* <Marker position={defaultCenter} /> */}
        <Circle center={defaultCenter} radius={circleRadius} />
      </GoogleMap>
    </>
  );
};

export default MapComponent;
