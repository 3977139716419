import React from "react";
import { useTheme } from "@mui/material/styles";
import MapComponent from "views/components/map";
import MainCard from "ui-component/cards/MainCard";
import styled from "@emotion/styled";

const Footer = () => {
  const theme = useTheme();

  const Footer = styled("footer")(({ theme }) => ({
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(3),
    },
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {},
  }));

  return (
    <Footer>
      {/* Your footer content goes here */}
      <MainCard title="Location (Redmond Ridge)">
        <MapComponent latitude={47.69465} longitude={-122.0443} />
      </MainCard>
    </Footer>
  );
};

export default Footer;
