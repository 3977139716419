// assets
import { IconDashboard } from "@tabler/icons";

// constant
const icons = { IconDashboard };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

// Currently using as title
const dashboard = {
  id: "dashboard",
  title: "Navigate Your Elegance",
  type: "group",
  children: [
    // {
    //   id: 'default',
    //   title: 'Dashboard',
    //   type: 'item',
    //   url: '/dashboard/default',
    //   icon: icons.IconDashboard,
    //   breadcrumbs: false
    // }
  ],
};

export default dashboard;
