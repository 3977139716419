import { configureStore } from "@reduxjs/toolkit";
import customizationReducer from "./customizationReducer";

// ==============================|| REDUX - MAIN STORE ||============================== //

// const store = createStore(reducer);
const store = configureStore({
  reducer: {
    customization: customizationReducer,
  },
});
const persister = "Free";

export { store, persister };
