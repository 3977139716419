import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";

// dashboard routing
const DashboardDefault = Loadable(
  lazy(() => import("views/dashboard/Default"))
);

// Landing routing
const Landing = Loadable(lazy(() => import("views/landing")));

// utilities routing
const UtilsTypography = Loadable(
  lazy(() => import("views/utilities/Typography"))
);
const UtilsColor = Loadable(lazy(() => import("views/utilities/Color")));
const UtilsShadow = Loadable(lazy(() => import("views/utilities/Shadow")));
const UtilsMaterialIcons = Loadable(
  lazy(() => import("views/utilities/MaterialIcons"))
);
const UtilsTablerIcons = Loadable(
  lazy(() => import("views/utilities/TablerIcons"))
);

// sample page routing
const SamplePage = Loadable(lazy(() => import("views/sample-page")));

// Services page routing
const ServicesPage = Loadable(lazy(() => import("views/services")));

// Contact page routing
const ContactPage = Loadable(lazy(() => import("views/contact")));

const Portfolio = Loadable(lazy(() => import("views/portfolio")));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "",
      element: <Landing />,
    },
    {
      path: "services",
      element: <ServicesPage />,
    },
    {
      path: "portfolio",
      element: <Portfolio />,
    },
    {
      path: "contact",
      element: <ContactPage />,
    },
  ],
};

export default MainRoutes;
