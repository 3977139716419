// assets
import { IconHome, IconAffiliate, IconMessage2 } from "@tabler/icons";
import Face2OutlinedIcon from "@mui/icons-material/Face2Outlined";

// constant
const icons = {
  IconAffiliate,
  IconHome,
  IconMessage2,
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
  id: "pages",
  title: "",
  // caption: "Pages Caption",
  type: "group",
  children: [
    {
      id: "default",
      title: "Home",
      type: "item",
      url: "/",
      icon: icons.IconHome,
      breadcrumbs: true,
    },
    {
      id: "services",
      title: "Services",
      type: "item",
      url: "/services",
      icon: icons.IconAffiliate,
      breadcrumbs: true,
    },
    {
      id: "portfolio",
      title: "See my work",
      type: "item",
      url: "/portfolio",
      icon: Face2OutlinedIcon,
      breadcrumbs: true,
    },
    {
      id: "contact",
      title: "Contact",
      type: "item",
      url: "/contact",
      icon: icons.IconMessage2,
      breadcrumbs: true,
    },

    // {
    //   id: 'authentication',
    //   title: 'Authentication',
    //   type: 'collapse',
    //   icon: icons.IconKey,
    //   children: [
    //     {
    //       id: 'login3',
    //       title: 'Login',
    //       type: 'item',
    //       url: '/pages/login/login3',
    //       target: true
    //     },
    //     {
    //       id: 'register3',
    //       title: 'Register',
    //       type: 'item',
    //       url: '/pages/register/register3',
    //       target: true
    //     }
    //   ]
    // }
  ],
};

export default pages;
